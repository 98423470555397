<template>  
  <ion-page>
    <ion-content class="ion-padding ion-margin-top ">
      <logo :logo="mainLogo" class="logo" />
      <h2>{{ message }}</h2>
      <div class="psw-reset-container" v-if="mode == 'resetPassword' && email">
        <p>
          <label for="password">Email: <b>{{ email }}</b></label>
        </p>
        <p>
          <input type="password" class="password" ref="password" placeholder="New Password*"/>
          <i
            class="toggle-password bi bi-eye-slash"
            @click="togglePassword()"
          ></i>
        </p>
        <p class="psw-rule" :class="{ active: invalidPassword }">
            <ul>
                <li>at least 8 characters</li>
                <li>1 capitalize letter</li>
                <li>1 lowercase letter</li>
                <li>1 number</li>
          </ul>
        </p>
        <button class="submit-btn" @click="confirmNewPassword()">
          Update Password
        </button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { auth } from '../firebase'
import logo from '../components/Logo'

export default {
    name: 'FbAuthenticationHanlder',
    components: {
        logo,
    },
    data() {
        return {
            message: '',
            email: '',
            actionCode: '',
            mode: '',
            invalidPassword: false,
            mainLogo: require('../assets/logo.png'),
        }
    },
    methods: {
        process() {
            this.mode = this.$route.query.mode
            this.actionCode = this.$route.query.oobCode
            // Handle the user management action.
            switch (this.mode) {
                case 'resetPassword':
                    // Display reset password handler and UI.
                    this.handleResetPassword(auth, this.actionCode)
                    break
                case 'recoverEmail':
                    // Display email recovery handler and UI.
                    this.handleRecoverEmail(auth, this.actionCode)
                    break
                case 'verifyEmail':
                    // Display email verification handler and UI.
                    this.handleVerifyEmail(auth, this.actionCode)
                    break
                default:
                // Error: invalid mode.
            }
        },
        handleResetPassword(auth, actionCode) {
            this.message = 'Password Reset'
            auth.verifyPasswordResetCode(actionCode)
                .then((email) => {
                    this.email = email
                })
                .catch(() => {
                    this.message = 'Reset code expired! Please request password reset again in the app.'
                })
        },
        confirmNewPassword() {
            const validatePassword = (password) => {
                return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
            }

            const newPassword = this.$refs.password.value
            if (!validatePassword(newPassword)) {
                this.invalidPassword = true
                return
            }

            this.invalidPassword = false
            this.mode = ''
            auth.confirmPasswordReset(this.actionCode, newPassword)
                .then(() => {
                    this.message = 'Your password has been updated. Please continue sign in process in the app.'
                })
                .catch((error) => {
                    this.message = 'Error while reseting password: ' + error.message
                })
        },
        handleRecoverEmail(auth, actionCode) {
            console.log(actionCode)
            // not supported
        },
        handleVerifyEmail(auth, actionCode) {
            auth.applyActionCode(actionCode)
                .then(() => {
                    this.message = 'Email is verified. You would be signed in automatically or you can sign in with registered email and password.'
                })
                .catch(() => {
                    this.message =
                        'Verification code expired! Please request a new verification email in the app.'
                })
        },
        togglePassword() {
            const password = this.$refs.password
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password'
            password.setAttribute('type', type)
            // toggle the eye / eye slash icon
            password.classList.toggle('bi-eye')
        },
    },
    mounted() {
        this.process()
    },
}
</script>

<style scoped lang="scss">
.psw-reset-container {
    width: 80%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    .password {
        height: 30px;
        padding: 5px 10px;
        font-size: 16px;
        width: 200px;
    }
    i {
        font-size: 25px;
        margin-left: -35px;
        cursor: pointer;
    }
    .submit-btn {
        color: #fff !important;
        text-transform: capitalize;
        text-decoration: none;
        background: #67c4f1;
        padding: 15px;
        border-radius: 5px;
        display: inline-block;
        border: none;
        transition: all 0.4s ease 0s;
        font-size: 15px;
        cursor: pointer;
    }
    .psw-rule {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        &.active {
            color: red;
        }
        ul {
            margin-top: 0px;
            text-align: left;
        }
    }
    .logo {
        margin-top: 60px;
    }
}
</style>
